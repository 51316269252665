import request from 'superagent';
// ------------------------------------
// Constants
// ------------------------------------
export const NAV_LOADING = 'NAV_LOADING';
export const NAV_LOADED = 'NAV_LOADED';

// ------------------------------------
// Actions
// ------------------------------------
export function loadNav() {
  return dispatch => {
    dispatch({ type: 'NAV_LOADING', payload: true });

    request
      .post(window.BofConfig.adaptiveUrl + 'WebServices/cms/Page.asmx/GetMenu')
      .send({ site: 5 })
      .set('Accept', 'application/json')
      .end((err, response) => {
        if (err || !response.ok) {
          dispatch({
            type: 'NAV_LOADED',
            payload: {
              nav: [],
              navLoading: false
            }
          });
          return;
        }
        response = response.body.d;

        dispatch({
          type: 'NAV_LOADED',
          payload: {
            nav: response.records,
            navLoading: false
          }
        });
      });
  };
}

// ------------------------------------
// Action Handler
// ------------------------------------
const ACTION_HANDLERS = {
  [NAV_LOADING]: (state, action) => ({ ...state, navLoading: action.payload }),
  [NAV_LOADED]: (state, action) => ({
    ...state,
    navLoading: action.payload.navLoading,
    nav: action.payload.nav
  })
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  navLoading: false,
  nav: []
};

export default function(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
