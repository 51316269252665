import React, { useMemo, useCallback } from 'react';
import {
    ClusterSource,
    clusterStackStyle,
    clusterText,
    Select,
} from 'react-openlayers';
import {Style, Icon, Circle, Fill } from 'ol/style';
import {getSymbol } from '../utils/Icons';
import { connect } from 'react-redux';

const mapStateToProps = (state) => ({
    articles: state.articles.articles,
    articlesLoading: state.articles.loading,
    articlesLoadState: state.articles.loadState,
});

const clusterLabelText = clusterText({
    color: '#333',
    font: '600 10px sans-serif',
  });
  
  const styleFn = (f) => {
    const size = 0.5;
    const symbol = getSymbol(f.get('sourceDataIndex'));
  
    if (!size || !symbol) return new Style();
    return new Style({
      image: new Icon({
        src: symbol,
        scale: size,
      }),
    });
  };
  
  const itemClusterStyle = clusterStackStyle({
    clusterLabelText,
    clusterLabelOffsetX: 12,
    clusterLabelOffsetY: 12,
    clusterLabelBackgroundImage: new Circle({
      radius: 8,
      fill: new Fill({ color: '#fff' }),
    }),
    featureStyle: styleFn,
  });
  
  const ArticleLayer = ({ articles, onFeatureClick, activeSources, articlesLoading, }) => {
      
    const features = useMemo(() => {
        if (!articlesLoading) {
            return Object.entries(articles)
              .filter((a) => activeSources.includes(a[1].id.split('_')[0]))
              .map((a) => {
                a[1].olFeature.setProperties({
                  style: a[1].properties.styl,
                  sourceDataIndex: a[1].id.split('_')[0],
                  id: a[1].id,
                });
                return a[1].olFeature;
              });
        }
    }, [articles, activeSources, activeSources]);

    const onItemSelect = useCallback(
        (f) => {
          if (!f.length) {
            return;
          }
          const cluster = f[0];
          const features = cluster.get('features');

        if (features.length === 1) {
        const f = features[0];
        onFeatureClick(f.get('id'));
      }
      return false;
    },
    [onFeatureClick]
  );
  
    return (
      <ClusterSource
        key={'articles-layer'}
        layerName='articlelayer'
        features={features || []}
        style={itemClusterStyle}
        clusterThreshold={15}>
        <Select onSelect = {onItemSelect} />
      </ClusterSource>
    );
  };
  
  export default connect(mapStateToProps)(ArticleLayer);