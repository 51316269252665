import React from 'react';
import { browserHistory } from 'react-router';
import { Button, Glyphicon } from 'react-bootstrap';
import { Articles, ensureArticleModuleConfig, articleActions, } from 'react-article-module';
import PropTypes from 'prop-types';
import { WmsLayer } from 'react-openlayers';
import LazyLoad from '../lib/lazyload';
import './ArticleView.scss';
import WmsLegends from '../components/WmsLegends';
import LegendBtns from '../components/LegendBtns';
import { translations as ArticlModuleTranslations } from 'react-article-module';
import translations from '../translations';
import ArticleLayer from '../containers/ArticleLayer';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const baseUrl = 'ekosystemy';

const sourceUrl = '/mapa';

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(Object.assign({}, articleActions), dispatch);
};

class ArticleView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLegends: false,
    };
  }

  componentDidMount() {
    this.lazyLoad = new LazyLoad({
      threshold: 500,
      container:
        window.innerWidth < 769
          ? window
          : document.getElementById('articleView-scrollPane'),
      elements_selector: '.article-entry img',
    });
  }

  componentWillUnmount() {
    this.lazyLoad.destroy();
  }

  getTranslations (lang) {
    return Object.assign(translations[lang], ArticlModuleTranslations[lang]);
  }

  onFeatureClick = (id) => {
    browserHistory.push(baseUrl + '/' + id.replace('_', '/'));
  };

  onSourceSelect = (sources) => {
    const { setActiveSources } = this.props;

    browserHistory.push(sourceUrl + '/' + sources.join('+'));
    setActiveSources(sources.join('+'));
  };

  get activeSources() {
    let {
      params: { activeSources },
      sources,
    } = this.props;
    activeSources = activeSources ? activeSources.split('+') : [];

    if (!activeSources.length) {
      activeSources = sources
        .filter((s) => {
          return s.pressed && s.dataIndex;
        })
        .map((item) => item.dataIndex);
    }
    return activeSources;
  }



  render() {
    const { enableArticleComments } = this.props;
    const { showLegends } = this.state;
    
    const pushPath = (path) => browserHistory.push('/' + path);
    const activeSources = this.activeSources;
    const openArticleId =
      this.props.params && this.props.params.pageId
        ? this.props.params.pageId + '_' + this.props.params.splat
        : undefined;

    return (
      <div className="article-view--container" id="articleView-scrollPane">
        {/* <WmsLayer
          uri="http://wms.gridw.geopanel.eu/qgis.ashx?map=6b26bb62-b800-4ed2-9fed-8d3edd7089c8"
          layerName="Szlaki piesze"
          id="szlaki-piesze"
          version="1.1.1"
          singleTile
          minZoom={7}
          zIndex={-1}
        /> */}
        {enableArticleComments && (
          <div className={''}>
            <Button bsStyle="default" disabled>
              <Glyphicon glyph="th-list" />
            </Button>
            <Button bsStyle="primary" onClick={() => pushPath('comments')}>
              <Glyphicon glyph="comment" />
            </Button>
          </div>
        )}
        <div className="article-view--buttonbar">
          <LegendBtns
            showLegends={showLegends}
            onShowLegendsClick={showLegends => this.setState({ showLegends })}
          />
        </div>
        {showLegends && (
          <WmsLegends
            urls={[
              'http://gridw.geopanel.eu/WebServices/generic/GetCustomLegend.ashx?F=ff58e74712eb2b7a419802009df1ca5058196214.png'
              //,
              //'http://wms.gridw.geopanel.eu/qgis.ashx?map=6b26bb62-b800-4ed2-9fed-8d3edd7089c8&LAYERS=Szlaki piesze&FORMAT=image/png&VERSION=1.1.1&SERVICE=WMS&REQUEST=GetLegendGraphic&LAYERTITLE=true&ITEMFONTSIZE=9&LAYERFONTSIZE=11'
            ]}
          />
        )}
        <div className={showLegends ? 'article-view--hidden' : ''}>
          <Articles  translations={this.getTranslations(window.BofConfig.globalLang)}
            activeSources={activeSources}
            onContentChange={() => this.lazyLoad.update()}
            onSourceSelect={this.onSourceSelect}
            onArticleOpen={article => {
              pushPath(baseUrl + '/' + article.id.replace('_', '/'));
              window.scrollTo(0, 0);
            }}
            thumbnailClassName="bof--thumbnail"
            useHoverStyle
            sourceSelectorPlaceholder={count =>
              count === 1
                ? '1 ' + translations[window.BofConfig.globalLang]['Adds']['sourceSelector1']
                : translations[window.BofConfig.globalLang]['Adds']['sourceSelectorAll'] + ': ' + count
            }
            openArticleId={openArticleId}
            hideLayers
          />
          <ArticleLayer
           onFeatureClick={this.onFeatureClick}
           activeSources = {activeSources} 
           />
        </div>
      </div>
    );
  }
}

ArticleView.propTypes = {
  params: PropTypes.object,
  enableArticleComments: PropTypes.bool,
  activeSources: PropTypes.array,
  t: PropTypes.func
};

export default connect(
  null,
  mapDispatchToProps
)(ensureArticleModuleConfig(ArticleView));
