import laki from '../static/symbols/laki.png';
import las from '../static/symbols/lasy.png';
import gory from '../static/symbols/gory.png';
import miasta from '../static/symbols/miasta.png';
import mokradla from '../static/symbols/torfowiska.png';
import morze from '../static/symbols/morza.png';
import pola from '../static/symbols/rolniczy.png';
import wody from '../static/symbols/wody_slodkie.png';


export function getSymbol(index) {
    //eslint-disable-next-line
    switch (index) {
        case 'gorski':
            return gory;
        case 'rola':
            return pola;
        case 'lasy':
            return las;
        case 'morza':
            return morze;
        case 'miasto':
            return miasta;
        case 'wody':
            return wody;
        case 'laki':
            return laki;
        case 'torfowiska':
            return mokradla;
    }
};
