import CoreLayout from '../containers/CoreLayout';
import MapLayout from '../containers/MapLayout';
import ArticleDetailView from '../views/ArticleDetailView';
import OmBofLayout from '../containers/OmBofLayout';
import PageView from '../views/PageView';
import ArticleView from '../views/ArticleView';

export default [  
  {
    path: 'mapa',
    component: MapLayout,
    childRoutes: [
      {
        path: ':activeSources*',
        component: ArticleView,
      },
    ],
    indexRoute: {
      component: ArticleView,
    },
  },
  {
    path: 'ekosystemy',
    component: MapLayout,
    childRoutes: [
      {
        path: ':pageId/*',
        component: ArticleDetailView
      }
    ]
  },
  {
    path: 'ombof',
    component: OmBofLayout,
    childRoutes: [
      {
        path: ':uri*',
        component: OmBofLayout
      }
    ]
  },
  {
    path: 'skjaergardstjenesten/marintavfall',
    component: OmBofLayout,
    childRoutes: [
      {
        path: ':uri*',
        component: OmBofLayout
      }
    ]
  },
  {
    path: '/',
    component: CoreLayout,
    childRoutes: [
      {
        path: ':uri*',
        component: PageView
      }
    ],
    indexRoute: {
      component: PageView
    }
  }
];
