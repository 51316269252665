import React from 'react';
import { IndexLink, browserHistory } from 'react-router';
import logo from './tradycyjne_pasterstwo_white.svg';
import PropTypes from 'prop-types';
import './Nav.scss';
import { Link } from './Link';
import { Search } from 'react-article-module';
import { translations as ArticlModuleTranslations } from 'react-article-module';
import translations from '../translations';

export class Nav extends React.Component {
  constructor() {
    super();
    this.state = {
      navOpen: false
    };
  }


  getTranslations (lang) {
    return Object.assign(translations[lang], ArticlModuleTranslations[lang])
  }
  

  render() {
    const { nav } = this.props;
    const { navOpen } = this.state;
    return (
      <div className="nav">
        <IndexLink to={translations[window.BofConfig.globalLang]['Adds']['logoUrl']} className="nav--logo" target={translations[window.BofConfig.globalLang]['Adds']['logoUrlTarget']}>
          <img src={logo} alt={translations[window.BofConfig.globalLang]['Adds']['logoAlt']} title={translations[window.BofConfig.globalLang]['Adds']['logoAlt']} />
        </IndexLink>
        <div className="nav--search">
          <Search
            onResultSelected={article =>
              browserHistory.push('/ekosystemy/' + article.id.replace('_', '/'))
            }
          />
        </div>
        <div className="nav--menu">
          <input
            type="checkbox"
            className="nav--hamburger"
            checked={navOpen}
            onClick={() => this.setState({ navOpen: !navOpen })}
            onChange={() => null}
          />
          <span />
          <span />
          <span />
          <div className="nav--items">
            <div className="nav--menu-links">
              {nav &&
                nav.map(n => (
                  <Link
                    key={n.uri}
                    to={n.menu_uri}
                    onClick={() => {
                      this.setState({ navOpen: !navOpen });
                    }}
                  >
                    {n.title}
                  </Link>
                ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Nav.propTypes = {
  nav: PropTypes.array
};

export default Nav;
