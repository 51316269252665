import React, { Component } from 'react';
import { Router } from 'react-router';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { Map, WmsLayer, LayerGroup, LayerSwitch } from 'react-openlayers';
import { TranslatorProvider } from 'react-translate';
import { translations as ArticlModuleTranslations, ArticleModule } from 'react-article-module';
import { addReducerToStore } from '../redux/store';
import translations from '../translations';
import { Button } from 'react-bootstrap';
import Icon from '../components/Icon';
import './App.scss';


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      center: window.innerWidth > 768 ? [2380000, 6760000] : [2100000, 6750000],
      zoom: window.innerHeight > 800 ?  7 : 6,
      counter: 0
    };
  }


  getTranslations(lang) {
    return Object.assign(translations[lang], ArticlModuleTranslations[lang]);
  }

  onSearchInit(reducer) {
    addReducerToStore('searchSources', reducer);
  }
  
  render() {
    const { center, zoom } = this.state;
    const { store, history, routes } = this.props;
    
    return (

      < Provider store={store} >
        <TranslatorProvider translations={this.getTranslations(window.BofConfig.globalLang)}>
          <Map //projection='EPSG:2180' 
            //projDefs={[
            // {
            //   epsg: 'EPSG:2180',
            //</TranslatorProvider>   def: '+proj=tmerc +lat_0=0 +lon_0=19 +k=0.9993 +x_0=500000 +y_0=-5300000 +ellps=GRS80 +units=m +no_defs'
            //  }
            //]}  
            center={center} zoom={zoom} history={history}
            zoomInTipLabel={translations[window.BofConfig.globalLang]['Adds']['zoomInTipLabel']}
            zoomOutTipLabel={translations[window.BofConfig.globalLang]['Adds']['zoomOutTipLabel']}
            trackTipLabel={translations[window.BofConfig.globalLang]['Adds']['trackTipLabel']}

            //extent={[523600,520600,553000,537400]} 
            minZoom={6} hideScaleLine track>
            <WmsLayer
                  baseLayer
                  id='OSM-WMS'
                  key='OSM-WMS'
                  name='Open Street Map (WMS)'
                  uri='https://ows.terrestris.de/osm-gray/service?'
                  layerName='OSM-WMS'
                  //projCode='2180'
                  version='1.1.1'
                  zIndex={-1}
                /> 
                <div className="map-globe--components">
              <Button className="tipsbox-btn-globe" title="Przejdź do domyślnego zasięgu" onClick={() => {

                if (this.state.counter === 0) {
                  this.setState({
                    center: window.innerWidth > 768 ? [2124000, 6710000] : [2100000, 6749999],
                    center: window.innerHeight > 800 ? [2124000, 6710000] : [2100000, 6749999],
                    zoom: window.innerHeight > 800 ?  7 : 6,
                    
                    counter: 1,
                  });
                } else if (this.state.counter === 1) {
                  this.setState({
                    center: window.innerWidth > 768 ? [2124000, 6710001] : [2100000, 6750000],
                    center: window.innerWidth > 800 ? [2124000, 6710001] : [2100000, 6750000],
                    zoom: window.innerHeight > 800 ?  7 : 6,
                    counter: 0,
                  });
                }
              }
              }>
                <Icon name="globe" height={22} width={22} />
              </Button>
            </div>
            <ArticleModule
              adaptiveUrl={window.BofConfig.adaptiveUrl}
              articleModuleUuid={window.BofConfig.articleModuleUuid}
              onSearchInit={this.onSearchInit}
            //enableSearch={window.BofConfig.enableSearch}
            >
              <Router history={history} children={routes} />
            </ArticleModule>
          </Map>
        </TranslatorProvider>
      </Provider >
    );
  }
}

App.propTypes = {
  store: PropTypes.object.isRequired,
  routes: PropTypes.any.isRequired,
  history: PropTypes.object.isRequired
};

export default App;
